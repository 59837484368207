<template>
  <div class="SocialNetworkCallback" />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    provider: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['authUserId'])
  },
  mounted () {
    this.sendTokenToApi()
  },
  methods: {
    sendTokenToApi () {
      const loading = this.$loading({
        lock: true,
        text: 'Saving token',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const payload = {
        user: this.authUserId,
        code: this.$safeGet(this.$route, 'query.code', null),
        brand: this.activeBrandId
      }
      this.$api.post(`oauth/social/${this.provider}/link`, payload)
        .then(response => {
          loading.close()
          this.$router.push({ query: { token_received: true, media: this.provider } })
        })
        .catch(error => {
          loading.close()
          this.$displayRequestError(error, `A link between ${this.provider} and your profile could not be created, please close this window and try again`, 0)
        })
    }
  }
}
</script>
